import React, { useState } from "react";
import moment from "moment";
import Styles from "./MenteeSessions.module.css";
import {
  UpBlueChevron,
  BlueChevron,
  CalendarIcon,
  CancelCircle,
  TickSign,
  DollarIcon,
} from "../../Icons";
import AvatarPlaceholder from "../../../images/avatar-placeholder.gif";
import { ALLOWED_STATUS } from "entities/Session";
import { useAlert } from "@blaumaus/react-alert";
import { useNavigate } from "react-router-dom";
import { MenteeSessionQueryResult } from "entities/MenteeSession";
import posthog from "posthog-js";
import ShowDetailsButton from "../../Atoms/ShowDetailsButton/ShowDetailsButton.component";
import SessionStatusTag from "../../Molecules/SessionStatusTag/SessionStatusTag";

interface SessionCardProps {
  session: MenteeSessionQueryResult;
}

const MenteeSessionCard = ({ session }: SessionCardProps) => {
  const [show_details, setShowDetails] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();

  const join_call = () => {
    // SEGEMENT TODO - track join call
    if (session && session.videoRoomLink) {
      window.open(session.videoRoomLink, "_blank");
    } else {
      alert.error("Your session dosen't have a video room. Contact support");
    }
  };

  const handle_reschedule_session = () => {
    posthog.capture("mentee_clicked_reschedule_session", {
      status: session.status,
      session_id: session._id,
    });
    navigate(`/reschedule/${session._id}`);
  };

  const toggle_show_details = () => setShowDetails(!show_details);

  const goToMentorProfile = () =>
    navigate(`/profile/${session.mentor_profile.slug}`);

  return (
    <div className={Styles.sessionCardContainer}>
      <div className={Styles.sessionCard}>
        <img
          src={
            (session.mentor_profile &&
              session.mentor_profile.profileImage.url) ||
            AvatarPlaceholder
          }
          alt="card-profile"
          width="120px"
          height="120px"
          style={{ cursor: "pointer" }}
          className={Styles.avatar}
          onClick={goToMentorProfile}
        />
        <div className={Styles.cardInfo}>
          <div className={Styles.detail}>
            <div className={Styles.basicDetail}>
              <h3>{`${session.mentor_profile.given_name} ${session.mentor_profile.family_name}`}</h3>
              <p className={Styles.objective}>{session.mentor_profile.pitch}</p>
            </div>
          </div>

          <div className={Styles.infoSection}>
            <div className={Styles.dateInfo}>
              <img
                width="15px"
                height="15px"
                src={CalendarIcon}
                alt="calendar-icon"
              />
              <p>{moment(session.ideal_proposed_time).format("llll")}</p>
            </div>
            {session.price && (
              <div className={Styles.dateInfo}>
                <img
                  width="15px"
                  height="15px"
                  src={DollarIcon}
                  alt="dollar-icon"
                />
                <p>{Intl.NumberFormat("en-US").format(session.price)}</p>
              </div>
            )}
            <SessionStatusTag
              status={session.status}
              duration={session.duration}
            />
          </div>
        </div>
        <ShowDetailsButton
          show_details={show_details}
          toggle_details={toggle_show_details}
        />
      </div>
      {show_details && <hr className={Styles.lineSeparator} />}
      {show_details && (
        <div className={Styles.sessionDetails}>
          <p className={Styles.fieldTitle}>AGENDA</p>
          <p className={Styles.fieldDetail}>{session.description}</p>
          {session.okr && session.okr.title && (
            <>
              <p className={Styles.fieldTitle}>OKR</p>
              <p className={Styles.okrLink}>{session.okr.title}</p>
            </>
          )}
          <div className={Styles.action}>
            {session.status === ALLOWED_STATUS.COMPLETED &&
              !session.mentee_review && (
                <button
                  onClick={() => navigate(`/session-review/${session._id}`)}
                  className={Styles.blue_button}
                >
                  Review Session
                </button>
              )}
            {session.status === ALLOWED_STATUS.UPCOMING && (
              <button onClick={join_call} className={Styles.blue_button}>
                Join call
              </button>
            )}
            {[
              ALLOWED_STATUS.UPCOMING,
              ALLOWED_STATUS.RESCHEDULE,
              ALLOWED_STATUS.REQUEST,
            ].includes(session.status) && (
              <button
                onClick={handle_reschedule_session}
                className={Styles.black_button}
              >
                Reschedule
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenteeSessionCard;
