import DropdownSelector from "components/Atoms/DropdownSelector";
import { PROFILE_TYPE } from "entities/Profile";
import useSwitchProfile from "hooks/user/useChangeProfile";
import useMyProfile from "hooks/useMyProfile";

const ProfileSwitcher = () => {
  const { type } = useMyProfile();
  const { switchTo, profile_types } = useSwitchProfile();

  const capitalizeFirstLetter = (str?: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const changeProfile = ({
    value,
    title,
  }: {
    value: PROFILE_TYPE;
    title: string;
  }) => switchTo(value);

  return (
    <>
      {profile_types && profile_types.length > 1 && (
        <DropdownSelector
          id="ProfileSelector"
          key={"profile-selector"}
          label="Select Profile"
          value={{
            title: capitalizeFirstLetter(type),
            value: type,
          }}
          options={profile_types.map((type) => ({
            title: capitalizeFirstLetter(type),
            value: type,
          }))}
          onSelect={changeProfile}
          dark={true}
        />
      )}
    </>
  );
};

export default ProfileSwitcher;
