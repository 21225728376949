import React from "react";
import { UpBlueChevron } from "../../Icons";
import Styles from "./ShowDetailsButton.module.css";

interface ShowDetailsButtonProps {
  show_details: boolean;
  toggle_details: () => void;
}

const ShowDetailsButton: React.FC<ShowDetailsButtonProps> = ({
  show_details,
  toggle_details,
}) => {
  return (
    <button className={Styles.details_button} onClick={toggle_details}>
      Details
      <img
        width="15px"
        height="15px"
        src={UpBlueChevron}
        alt="chevron"
        className={Styles.chevron}
        style={{
          transform: show_details ? "rotate(0deg)" : "rotate(180deg)",
        }}
      />
    </button>
  );
};

export default ShowDetailsButton;
