// webapp/src/components/Molecules/SessionStatusTag/SessionStatusTag.tsx
import React from "react";
import { ALLOWED_STATUS } from "entities/Session";
import { CancelCircle, TickSign } from "components/Icons";
import Styles from "./SessionStatusTag.module.css";

interface SessionStatusTagProps {
  status: ALLOWED_STATUS;
  duration?: number;
}

const SessionStatusTag: React.FC<SessionStatusTagProps> = ({
  status,
  duration,
}) => {
  const is_canceled = [
    ALLOWED_STATUS.CANCELED,
    ALLOWED_STATUS.DECLINED,
    ALLOWED_STATUS.EXPIRED,
  ].includes(status);

  const is_completed = status === ALLOWED_STATUS.COMPLETED;

  if (!is_canceled && !is_completed) return null;

  return (
    <div className={is_completed ? Styles.completedTag : Styles.canceledTag}>
      <img
        width="12px"
        height="12px"
        src={is_completed ? TickSign : CancelCircle}
        alt=""
      />
      <p>
        {is_completed
          ? `Session Complete - ${duration} min`
          : `Session ${status}`}
      </p>
    </div>
  );
};

export default SessionStatusTag;
