import { Grid } from "react-loader-spinner";
import React from "react";

import { useAddKeyResult } from "hooks/mentee/okr/key_result/useAddKeyResult";
import { useDeleteKeyResult } from "hooks/mentee/okr/key_result/useDeleteKeyResult";
import { useToggleKeyResultStatus } from "hooks/mentee/okr/key_result/useToggleKeyResultStatus";
import { useObjective } from "hooks/mentee/okr/useObjective";
import { formatObjectiveForEditView } from "./helpers";

import {
  ObjectiveService,
  ObjectiveView,
} from "screens/Home/mentee/Objectives/service/useObjectivesService";

import ModifyObjective, {
  ObjectiveValidation,
} from "components/Molecules/ModifyObjective/ModifyObjective";
import IconButton from "components/Atoms/IconButton";
import { CrossIcon, PencilIcon } from "components/Icons";
import ViewObjectiveComponent from "components/Molecules/ViewObjective/ViewObjective.component";

import styles from "./Objective.module.css";

const Objective = (props: ObjectiveService) => {
  const { data, isFetched } = useObjective(props.objective_id, {
    enabled: !!props.objective_id,
  });
  const { mutate: addKeyResult } = useAddKeyResult(props.objective_id);
  const { mutate: deleteKeyResult } = useDeleteKeyResult(props.objective_id);
  const { mutate: toggleKeyResult } = useToggleKeyResultStatus(
    props.objective_id
  );

  const handleCreateObjective = (
    values: ObjectiveValidation,
    setSubmitting: (value: boolean) => void
  ) => {
    setSubmitting(true);
    const new_objective = {
      ...values,
      due_date: values.due_date.value,
      subject: values.subject.value,
    };
    props.save(new_objective);
  };

  const handleUpdateObjective = (
    values: ObjectiveValidation,
    setSubmitting: (value: boolean) => void
  ) => {
    setSubmitting(true);
    const objective_update = {
      ...values,
      due_date: values.due_date.value,
      subject: values.subject.value,
    };
    props.update(props.objective_id, objective_update);
  };

  const handleToggleStatus = (objective_id: string) => {
    const objective_update = {
      title: data?.title || '',
      status: data?.status === "in_progress" ? "completed" : "in_progress",
      due_date: data?.due_date || '',
      subject: data?.subject || 'Growth',
      key_results: data?.key_results || [],
      attachments: data?.attachments || []
    };
    props.update(objective_id, objective_update);
    props.close();
  };

  const ObjectiveAction = React.memo(() => {
    switch (props.objective_view) {
      case ObjectiveView.VIEW:
        return isFetched && !!data ? (
          <ViewObjectiveComponent
            sidebar={true}
            {...data}
            sessions={[]}
            activity={[]}
            addKeyResult={addKeyResult}
            deleteKeyResult={deleteKeyResult}
            toggleKeyResult={toggleKeyResult}
            onToggleStatus={handleToggleStatus}
          />
        ) : (
          <Grid
            wrapperStyle={{ margin: "auto", display: "block" }}
            color="#5440F0"
            height={32}
            width={32}
          />
        );
      case ObjectiveView.EDIT:
        return isFetched && !!data ? (
          <ModifyObjective
            modification_type="update"
            initial_values={formatObjectiveForEditView(data)}
            saveObjective={handleUpdateObjective}
            // uploadAttachment={uploadAttachment}
          />
        ) : (
          <Grid
            wrapperStyle={{ margin: "auto", display: "block" }}
            color="#5440F0"
            height={32}
            width={32}
          />
        );
      case ObjectiveView.CREATE:
        return (
          <ModifyObjective
            modification_type="create"
            saveObjective={handleCreateObjective}
            // uploadAttachment={uploadAttachment}
          />
        );
      default:
        return (
          <Grid
            wrapperStyle={{
              margin: "auto",
              marginTop: "240px",
              display: "block",
            }}
            color="#5440F0"
            height={42}
            width={42}
          />
        );
    }
  });

  return (
    <div data-testid="Objective">
      <div className={styles.sidebar_actions}>
        <IconButton label="Close" icon={CrossIcon} onClick={props.close} />
        {props.objective_view === ObjectiveView.VIEW && (
          <IconButton
            label="close"
            icon={PencilIcon}
            onClick={() => props.edit(props.objective_id)}
          />
        )}
      </div>
      <div className={styles.action_container}>
        <ObjectiveAction />
      </div>
    </div>
  );
};

export default Objective;
