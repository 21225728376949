import { Coin, DashboardActive, Trophy } from "../../Icons";
import useNotificationDismissal from "hooks/ui/useNotificationDismissal";
import BannerNotification from "components/Atoms/BannerNotification";
import styles from "./ObjectiveBenifitsBanner.module.css";

const ObjectiveBenifitsBanner: React.FC = () => {
  const { dismissed, dismissNotification } = useNotificationDismissal(
    "objective_benifits_banner"
  );

  if (dismissed) return null;

  return (
    <BannerNotification
      isDisplayed={!dismissed}
      onDismiss={dismissNotification}
    >
      <div className={styles.objective_notification_container}>
        <h3>How do Objectives help?</h3>
        <div className={styles.objective_benifits_container}>
          <div>
            <img src={Coin} />
            <h4>Save time and money</h4>
            <p>
              Your mentor can see your OKRs. Help them do prep work and save
              valuable time in each session.
            </p>
          </div>
          <div>
            <img src={Trophy} />
            <h4>Focus effort</h4>
            <p>
              By focusing on a few goals every quarter, you dramatically
              increase your chances of success.
            </p>
          </div>
          <div>
            <img src={DashboardActive} />
            <h4>Iterate quickly</h4>
            <p>
              Understanding what’s causing you to fail or succeed every quarter
              keep you accountable and allows you to make adjustments.
            </p>
          </div>
        </div>
      </div>
    </BannerNotification>
  );
};

export default ObjectiveBenifitsBanner;
